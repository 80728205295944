@import 'styles/_mixins.scss';

.glossary-container {
  background-color: var(--clr-goldenrod-yellow);

  .accordion {
    .accordion-item {
      margin: 0.5rem 0;
      background-color: var(--clr-goldenrod-yellow);
      border: none;
      box-shadow: none;
    }

    .accordion-header > button {
      display: flex;
      justify-content: space-between;
      background-color: var(--clr-goldenrod-yellow);
      border: none;
      border-bottom: 0.1rem solid var(--clr-black);
      box-shadow: none;

      &::after {
        display: none;
      }
    
      &:not(.collapsed) {
        color: var(--clr-black);
        background-color: var(--clr-goldenrod-yellow);
        box-shadow: none;
    
        .glossary-accordion__header-icon {
          width: 1.1rem;
          height: 0.6rem;
          background-image: url('../../../icons/collapse-arrow.svg');
        }
      }
    
      &:focus {
        box-shadow: none;
      }
    
      .glossary-accordion__header-icon {
        width: 0.6rem;
        height: 1.1rem;
        background-image: url('../../../icons/expand-arrow.svg');
        background-size: cover;
      }
    }
  }
}

.app-ar {
  .glossary-container {
    .accordion {
      .accordion-header > button {
        &:not(.collapsed) {      
          .glossary-accordion__header-icon {
            transform: rotate(0);
          }
        }

        .glossary-accordion__header-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@include respond-to(large) {
  .glossary-container {
    .accordion-header > button {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
}

@include respond-to(medium) {
  .glossary-container {
    .accordion-header > button {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}

@include respond-to(tablet) {
  .glossary-container {
    .accordion-header > button {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
}

@include respond-to(small) {
  .glossary-container {
    .accordion-header > button {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}
