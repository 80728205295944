@import 'styles/_mixins.scss';

#header {
  @include app-container();
  margin: 1.2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: calc(100% - 2.4rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  background-color: rgba(#ffffff, 0.08);
  border-radius: 1.8rem;
  backdrop-filter: blur(0.2rem);

  &.header-dark {
    background-color: rgba(8, 8, 8, 0.08);

    .nav-link {
      color: var(--clr-chinese-black);
    }
  }

  .header-nav__item {
    font-size: 1.4rem;

    &:hover {
      color: var(--clr-orange-soda);
    }
  }

  /* Overrides */

  .navbar-nav {
    gap: 2rem;
  }

  .nav-link {
    color: var(--clr-white);
    font-size: 1.4rem;
  }

  .navbar-toggler {
    padding: 1rem;
    background-color: var(--clr-black);
    border-radius: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-toggler-icon {
    background-image: url('../../icons/hamburger.svg');
  }
}

@include respond-to(medium) {
  #header {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .header-logo {
      width: 75%;
    }

    .nav-link {
      font-size: 1rem;
    }

    .navbar-nav {
      gap: 1rem;
    }
  }
}

@include respond-to(tablet) {
  #header {
    .nav-link {
      font-size: 1.4rem;
    }
  }
}
