@import 'styles/_mixins.scss';

.security-intro {
  position: relative;
}

.security-intro__video {
  width: 100%;
  box-sizing: border-box;
  border: 0.4rem solid var(--clr-white);
  border-radius: 2.4rem;
}

#security-video-mobile {
  display: none;
}

.security-intro__content {
  padding: 0 3.8rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  box-sizing: border-box;

  & > p {
    max-width: 56rem;
  }
}

.security-content {
  padding: 0.4rem;
  position: relative;
  border: 0.4rem solid var(--clr-white);
  border-radius: 2.4rem;
  background-color: var(--clr-orange-soda);
  box-sizing: border-box;

  .security-content__wrapper {
    @include app-container();
    padding-top: 4rem;
    padding-bottom: 4rem;

    .wrapper {
      padding: 3rem 0;
      display: flex;
      border-bottom: 0.1rem solid var(--clr-black);

      &:last-child {
        border-bottom: none;
      }

      &.left-wrapper {
        & > p {
          width: 50%;
        }
      }

      &.right-wrapper {
        justify-content: flex-end;

        & > p {
          width: 40%
        }
      }
    }
  }
}

@include respond-to(large) {
  .security-intro__content > p {
    max-width: 40rem;
  }
}

@include respond-to(medium) {
  .security-intro__content > p {
    max-width: 30rem;
  }
}

@include respond-to(medium) {
  .security-intro__content > p {
    max-width: 30rem;
  }
}

@include respond-to(x-tablet) {
  .security-intro__content > p {
    max-width: 20rem;
  }
}

@include respond-to(tablet) {
  .security-content {
    .security-content__wrapper {
      .wrapper {
        &.left-wrapper,
        &.right-wrapper {
          & > p {
            width: 100%;
          }
        }
      }
    }
  }
}

@include respond-to(small) {
  .security-intro {
    margin-bottom: 3rem;
  }

  .security-intro__content {
    padding-top: 8rem;
    padding-bottom: 2rem;
    flex-direction: column;
  }

  #security-video {
    display: none;
  }

  #security-video-mobile {
    display: block;
  }
}