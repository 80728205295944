@import 'styles/_mixins.scss';

.default-text {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

@include respond-to(medium) {
  .default-text {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@include respond-to(tablet) {
  .default-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
