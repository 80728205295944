@import 'styles/_mixins.scss';

.footer {
  padding: 0.8rem;
  padding-top: 12rem;
  padding-bottom: 4rem;
  background: linear-gradient(127.58deg, #102442 4.08%, #52719E 48.71%, #FF9649 91.53%);
  border: 0.4rem solid var(--clr-white);
  border-radius: 2.4rem;
}

.footer__container {
  @include app-container();
  margin-bottom: 10rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5rem;
}

.footer__title {
  color: var(--clr-goldenrod-yellow);
  font-size: 6rem;
  line-height: 6.8rem;
}

.footer__description {
  & > h3 {
    color: var(--clr-goldenrod-yellow);
    font-size: 4rem;
    line-height: 4.8rem;
  }
}

.footer__links {
  margin: 3rem 0;
  margin-bottom: 16rem;
}

.footer__links-container {
  max-width: 50rem;
  padding: 2.4rem;
  display: flex;
  direction: ltr;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 2.4rem;
  font-family: 'FK Grotesk Thin';
}

.footer__links-item {
  margin-right: 0.8rem;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  flex: 1;
  background-color: var(--clr-black-icon);
  border-radius: 1.6rem;
  text-decoration: none;

  & > span {
    display: inline-block;
    margin: 0 1.6rem;
  }
}

.footer__icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-gray-lead);
  border-radius: 0.8rem;

  & > svg {
    width: 2rem;
    height: 2rem;
  }
}

.footer__icon-name {
  color: var(--clr-white);

  &:hover {
    color: var(--clr-orange-soda);
  }
}

.footer__form-title {
  color: var(--clr-white);
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.footer__input-wrapper {
  max-width: 50rem;
  display: flex;
  flex-direction: row;
  border: 0.1rem solid var(--clr-white);
  border-radius: 5rem;
}

.footer__form-input {
  padding: 2.8rem 3.4rem;
  border: none;
  flex: 1;
  color: var(--clr-white);
  background-color: transparent;
  outline: none;

  &::placeholder {
    color: var(--clr-white);
  }

  &::after {
    content: '';
    width: 4px;
    height: 4px;
  }
}

.footer__form-button {
  padding: 0 2.5rem;
  color: var(--clr-white);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.footer__checkbox-wrapper {
  margin: 1rem;

  .footer__link {
    color: var(--clr-white);

    &:hover {
      color: var(--clr-orange-soda);
    }
  }

  & > label {
    margin-left: 1rem;
    color: var(--clr-white);
  }
}

.footer__form-sent {
  padding-top: 2rem;
  color: var(--clr-white);
}

.footer__socials {
  @include app-container();
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__socials-copyright {
  color: var(--clr-white);
  font-size: 1.4rem;
  line-height: 3.2rem;
}

.footer__socials-icons {
  display: flex;
  gap: 1.6rem;
}

@include respond-to(large) {
  .footer__title {
    font-size: 5.2rem;
  }

  .footer__description > h3 {
    font-size: 3.2rem;
  }
}

@include respond-to(medium) {
  .footer__links {
    margin-bottom: 8rem;
  }

  .footer__title {
    font-size: 4.2rem;
  }

  .footer__description > h3 {
    font-size: 2.6rem;
  }
}

@include respond-to(tablet) {
  .footer__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 5rem;
  }

  .footer__links {
    margin-bottom: 4rem;
  }

  .footer__title {
    font-size: 3.6rem;
  }

  .footer__description > h3 {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

@include respond-to(x-small) {
  .footer__socials {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}

@include respond-to(small) {
  .footer__title {
    font-size: 2.8rem;
  }

  .footer__description > h3 {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  .footer__links-container {
    flex-flow: column wrap;
    gap: 1rem;
  }

  .footer__form-title {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .footer__socials-copyright {
    font-size: 1rem;
  }
}
