@import 'styles/_mixins.scss';

.default-subtitle {
  font-size: 4rem;
  line-height: 4.8rem;
}

@include respond-to(x-medium) {
  .default-subtitle {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}

@include respond-to(medium) {
  .default-subtitle {
    font-size: 3.2rem;
    line-height: 4rem;
  }
}

@include respond-to(x-tablet) {
  .default-subtitle {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
}

@include respond-to(tablet) {
  .default-subtitle {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}
