@import 'styles/_mixins.scss';

.card-text-container {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

@include respond-to(medium) {
  .card-text-container {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@include respond-to(tablet) {
  .card-text-container {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@include respond-to(small) {
  .card-text-container {
    font-size: 1rem;
    line-height: 1.6rem;
  }
}
