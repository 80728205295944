@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'styles/_mixins.scss';

.slider-card {
  padding: 4rem;
  max-width: 86rem;
  display: flex;
  flex-direction: column;
  background-color: var(--clr-chinese-black);
  color: var(--clr-white);
  border-radius: 2.4rem;

  .slider-card__icon {
    margin-bottom: 3rem;
    width: 2.6rem;
    height: 2.6rem;
  }

  .slider-card__title {
    margin-bottom: 1rem;
  }
}

@include respond-to(tablet) {
  .swiper {
    .swiper-slide {
      transform: unset;
    }
  }

  .slider-card {
    padding: 3rem;
    max-width: 70rem;
  
    .slider-card__icon {
      margin-bottom: 1.5rem;
      width: 2.2rem;
      height: 2.2rem;
    }
  
    .slider-card__title {
      margin-bottom: 1rem;
    }
  }
}

@include respond-to(small) {
  .slider-card {
    padding: 2rem;
    max-width: 60rem;
  
    .slider-card__icon {
      margin-bottom: 1rem;
      width: 1.8rem;
      height: 1.8rem;
    }
  
    .slider-card__title {
      margin-bottom: 0.5rem;
    }
  }
}

// Swiper overrides
.swiper.rewards-slider {
  .swiper-slide {
    transition: all 200ms linear;
    transform: scale(0.8);

    &.arabic {
      direction: rtl;
    }
  
    &.swiper-slide-active {
      transform: scale(1);
    }
  }

  .swiper-pagination {
    height: 4rem;
    position: relative;
    bottom: -1.5rem;

    .swiper-pagination-bullet {
      background-color: var(--clr-white);
      border: 0.1rem solid var(--clr-chinese-black);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        width: 2.4rem;
        border-radius: 2.4rem;
        background-color: var(--clr-chinese-black);
      }
    }
  }
}

