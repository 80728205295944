@import 'swiper/css';
@import 'styles/_mixins.scss';

.more-features {
  margin: 10rem 0;

  & > h3 {
    @include app-container();
    margin: 6rem 1.2rem;
    font-size: 4.8rem;
  }
}

.slider-wrapper {
  .card-custom {
    width: 41rem !important;
    height: 41rem;
    padding: 4rem;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    color: var(--clr-white);
    box-sizing: border-box;
    border-radius: 2.4rem;
    font-size: 2.4rem;
    line-height: 3.2rem;

    &.arabic {
      direction: rtl;
    }
  
    &.big {
      width: 71rem !important;
    }
  
    &.item1 {
      background-color: var(--clr-chinese-black);
    }
  
    &.item2 {
      background-color: var(--clr-goldenrod-yellow);
      color: var(--clr-chinese-black);
    }
  
    &.item3 {
      background-image: url('../../assets/images/feature3.png');
    }
  
    &.item4 {
      background-color: var(--clr-orange-soda);
    }
  
    &.item5 {
      background-color: var(--clr-nickel);
    }
  
    &.item6 {
      display: flex !important;
      align-items: center;
      justify-content: center;
      background-color: var(--clr-gray);
  
      .card-content {
        justify-content: center;
      }
    }
  
    &.item7 {
      background: linear-gradient(128.73deg, #0F2027 0%, #235364 45.66%, #EF472B 91.33%);
    }
  
    .card-content {
      display: flex;
      justify-content: space-between;
    }
    
    .card-footer-justified {
      text-align: center;
    }  
  }
}

@include respond-to(large) {
  .more-features > h3 {
    font-size: 4rem;
  }

  .slider-wrapper {
    .card-custom {
      width: 35rem !important;
      height: 35rem !important;
      font-size: 2rem;
      line-height: 2.8rem;
  
      &.big {
        width: 60rem !important;
      }
  
      img {
        width: 80%;
      }
    }
  }
}

@include respond-to(x-medium) {
  .more-features > h3 {
    font-size: 3.6rem;
  }

  .slider-wrapper {
    .card-custom {
      width: 30rem !important;
      height: 30rem !important;
      font-size: 1.6rem;
      line-height: 2.4rem;
  
      &.big {
        width: 50rem !important;
      }
  
      img {
        width: 70%;
      }
    }
  }
}

@include respond-to(x-tablet) {
  .more-features > h3 {
    font-size: 3.2rem;
  }

  .slider-wrapper {
    .card-custom {
      width: 30rem !important;
      height: 30rem !important;
  
      &.big {
        width: 45rem !important;
      }
    
      img {
        width: 60%;
      }
    }
  }
}

@include respond-to(x-small) {
  .more-features > h3 {
    font-size: 2.8rem;
  }

  .slider-wrapper {
    .card-custom {
      width: 25rem !important;
      height: 25rem !important;
      font-size: 1.2rem;
      line-height: 2rem;
  
      &.big {
        width: 32rem !important;
      }
    
      img {
        width: 50%;
      }
    }
  }
}
