@import 'styles/_mixins.scss';

.cookies-fixed-layer {
  padding: 0 2rem;
  width: 100%;
  position: fixed;
  bottom: 2rem;
  z-index: 20;
}

.cookies {
  display: flex;
  justify-content: flex-end;

  .cookies-card {
    padding: 2.4rem;
    width: 39.2rem;
    display: flex;
    gap: 1rem;
    color: var(--clr-white);
    background: rgb(76, 82, 97, 0.4);
    box-shadow: var(--clr-chinese-black) 0 0.2rem 1rem;
    border-radius: 2.4rem;
    backdrop-filter: blur(0.2rem);
  }

  .cookies-card__text {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .cookies-card__buttons {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .cookies-card__button {
    width: 47%;
    height: 4.8rem;
    background-color: var(--clr-chinese-black);
    color: var(--clr-white);
    border-radius: 1.6rem;
    border-color: var(--clr-chinese-black);
    font-size: 1.6rem;
    outline: none;

    &--outline {
      background-color: transparent;
      border-color: var(--clr-white);
    }

    &:hover {
      background-color: var(--clr-orange-soda);
      border-color: var(--clr-orange-soda);
    }
  }
}

@include respond-to(x-small) {
  .cookies-fixed-layer {
    padding: 0;
  }

  .cookies {
    justify-content: center;

    .cookies-card {
      width: 90%;
    }

    .cookies-card__buttons {
      flex-direction: column;
      gap: 1.5rem;
    }

    .cookies-card__button {
      width: 100%;
    }
  }
}
