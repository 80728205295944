@import 'styles/_mixins.scss';

.multi-intro {
  padding: 0.4rem;
  position: relative;
  border: 0.4rem solid var(--clr-white);
  border-radius: 2.4rem;
  background-color: var(--clr-black-icon);
  background-image: url('../../assets/images/multi-intro.png');
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;

  .multi-intro__content {
    @include app-container();
    padding-bottom: 8rem;
    width: 100%;
    max-width: 90rem;
    height: calc(100vh - 1.6rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 4rem;
    color: var(--clr-white);
  }
}

.multi-text {
  padding: 0.8rem;

  .multi-text__content {
    @include app-container();
    padding-top: 10rem;
    padding-bottom: 5rem;
    display: flex;
    justify-content: space-between;
    gap: 10rem;

    & > div {
      width: 50%;
    }

    .multi-text__content-figures > img {
      max-width: 80rem;
      width: 100%;
    }

    .multi-text__content-description > p {
      margin-bottom: 8rem;
    }

    .multi-text__content-image {
      width: 100%;
      display: none;
    }
  }
}

@include respond-to(medium) {
  .multi-text {
    .multi-text__content {
      flex-direction: column;
      justify-content: center;
      gap: 2rem;

      & > div {
        width: 100%;
      }

      .multi-text__content-figures {
        display: none;
      }

      .multi-text__content-description > p {
        margin-bottom: 0;
      }
  
      .multi-text__content-image {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
