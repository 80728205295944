@import 'styles/_mixins.scss';

.getpaid {
  padding: 0.4rem;
  position: relative;
  border: 0.4rem solid var(--clr-white);
  border-radius: 2.4rem;
  background: linear-gradient(128.73deg, #0F2027 0%, #235364 45.66%, #EF472B 91.33%);
  box-sizing: border-box;
}

.getpaid-intro {
  @include app-container();
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    width: 50%;
  }
}

.getpaid-intro__text {
  padding-top: 10rem;
  display: inline-flex;
  flex-direction: column;
  gap: 4rem;
  color: var(--clr-white);
}

.getpaid-intro__image {
  position: relative;
  top: 10rem;

  & > img {
    width: 100%;
  }
}

.getpaid-slider {
  padding-top: 16rem;
  padding-bottom: 5rem;
  
  .getpaid-slider__title {
    text-align: center;
  }

  .getpaid-slider__container {
    padding: 5rem 0;
    direction: ltr !important;
  }
}

@include respond-to(tablet) {
  .getpaid-intro {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .getpaid-intro__text {
    padding-top: 25rem;
  }
}