@import 'styles/_mixins.scss';

.features {
  padding: 8rem 1.2rem;
}

.features__container {
  @include app-container();
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5rem;
}

.features__description--title {
  font-size: 2.4rem;
}

.features__description--paragraph {
  font-size: 4rem;
  line-height: 4.8rem;
  margin-top: 4rem;
}

.features__bullets-item {
  margin-bottom: 7rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.features__bullets-item--title {
  margin: 3rem 0;
  font-size: 3.2rem;
}

.features__bullets-item--description {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.features__bullets-item-second {
  margin-top: 15rem;
}

@include respond-to(x-medium) {
  .features__description--paragraph {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  .features__bullets-item {
    margin-bottom: 5rem;
  }

  .features__bullets-item-second {
    margin-top: 3rem;
  }

  .features__bullets-item--title {
    margin: 2rem 0;
    font-size: 2.8rem;
  }
  
  .features__bullets-item--description {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

@include respond-to(x-tablet) {
  .features__description--title {
    font-size: 1.6rem;
  }

  .features__description--paragraph {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .features__bullets-item {
    margin-bottom: 3rem;
  }

  .features__bullets-item-second {
    margin-top: 2rem;
  }

  .features__bullets-item--icon {
    width: 3rem;
  }

  .features__bullets-item--title {
    margin: 1rem 0;
    font-size: 2.4rem;
  }
  
  .features__bullets-item--description {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

@include respond-to(small) {
  .features__container {
    @include app-container();
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 5rem;
  }  

  .features__bullets-item--icon {
    width: 2.4rem;
  }
}
