@import 'styles/_mixins.scss';

.academy-content {
  padding: 10rem 3.8rem;

  & > p {
    margin: 3rem 0;
    direction: ltr;
    font-family: 'FK Grotesk Regular';
  }
}

.academy-accordion__item.accordion-item {
  padding: 2.4rem 4rem;
  margin: 1.6rem 0;
  background-color: var(--clr-goldenrod-yellow);
  border-radius: 1.6rem !important;
}

.academy-accordion__header > button {
  padding: 0;
  display: flex;
  justify-content: space-between;
  background-color: var(--clr-goldenrod-yellow);
  border: none;
  border-radius: 1.6rem !important;
  box-shadow: none;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 500;

  &::after {
    display: none;
  }

  &:not(.collapsed) {
    color: var(--clr-black);
    background-color: var(--clr-goldenrod-yellow);
    box-shadow: none;

    .academy-accordion__header-icon {
      background-image: url('../../icons/collapse.svg');
    }
  }

  &:focus {
    border: none;
    box-shadow: none;
  }

  .academy-accordion__header-icon {
    width: 3.2rem;
    height: 3.2rem;
    background-image: url('../../icons/expand.svg');
    background-size: cover;
  }
}

.academy-accordion__body.accordion-body {
  margin-top: 3.2rem;
  padding: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.block {
  display: block;
}

@include respond-to(large) {
  .academy-accordion__item.accordion-item {
    padding: 2rem 3.2rem;
  }

  .academy-accordion__header > button {
    font-size: 2.2rem;
    line-height: 3rem;

    .academy-accordion__header-icon {
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .academy-accordion__body.accordion-body {
    margin-top: 2.6rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@include respond-to(medium) {
  .academy-accordion__item.accordion-item {
    margin: 1.2rem 0;
    padding: 1.6rem 2.8rem;
  }

  .academy-accordion__header > button {
    font-size: 2rem;
    line-height: 2.8rem;

    .academy-accordion__header-icon {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .academy-accordion__body.accordion-body {
    margin-top: 2rem;
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@include respond-to(tablet) {
  .academy-accordion__item.accordion-item {
    padding: 1.2rem 2rem;
  }

  .academy-accordion__header > button {
    font-size: 1.6rem;
    line-height: 2.6rem;

    .academy-accordion__header-icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .academy-accordion__body.accordion-body {
    margin-top: 1.2rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

@include respond-to(small) {
  .academy-accordion__item.accordion-item {
    margin: 0.8rem 0;
    padding: 0.8rem 1.6rem;
  }

  .academy-accordion__header > button {
    font-size: 1.2rem;
    line-height: 2.4rem;

    .academy-accordion__header-icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .academy-accordion__body.accordion-body {
    margin-top: 0.8rem;
    font-size: 1rem;
    line-height: 1.6rem;
  }
}
