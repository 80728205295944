@font-face {
  font-family: 'FK Grotesk Regular';
  src: url('../assets/fonts/FKGrotesk-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'FK Grotesk Bold';
  src: url('../assets//fonts/FKGrotesk-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'FK Grotesk Thin';
  src: url('../assets/fonts/FKGrotesk-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Arabic';
  src: url('../assets/fonts/SF-Arabic-font.ttf') format('truetype');
}
