@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'styles/_mixins.scss';

.screenshots {
  padding: 0.8rem;
  direction: ltr;
  background-color: var(--clr-orange-soda);
  border: 0.4rem solid var(--clr-white);
  border-radius: 2.4rem;
}

.screenshots__list {
  width: 130rem;
  height: 162rem;
  margin: 8rem auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.screenshots__slider {
  display: none;
  margin: 8rem auto;
}

.screenshots__list-item {
  margin: 0 auto;
  max-width: 30rem;
  max-height: 66rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  & > img {
    width: 100%;
    height: auto;
  }

  &:nth-child(2) {
    top: 20%;
  }

  &:nth-child(3) {
    top: 40%;
  }

  &:nth-child(4) {
    top: 60%;
  }
}

.slider-item {
  top: 0;
  background-position: center;
}

@include respond-to(large) {
  .screenshots__list {
    display: none;
  }

  .screenshots__slider {
    display: block;
  }
}

@include respond-to(small) {
  .screenshots__list-item.slider-item > img {
    width: 90%;
  }
}

// Swiper overrides
.swiper.screenshots-slider {
  .swiper-pagination {
    height: 4rem;
    position: relative;
    bottom: -1.5rem;

    .swiper-pagination-bullet {
      background-color: transparent;
      border: 0.1rem solid var(--clr-white);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        width: 2.4rem;
        border-radius: 2.4rem;
        background-color: var(--clr-white);
      }
    }
  }
}

@include respond-above(medium) {
    .screenshots__list {
    display: none;
  }

  .screenshots__slider {
    display: block;
  }
}
