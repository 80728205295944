$mediaBreakpoints: (
  small: 480px,
  x-small: 600px,
  tablet: 768px,
  x-tablet: 820px,
  medium: 1024px,
  x-medium: 1200px,
  large: 1440px,
  x-large: 1920px,
) !default;

@mixin respond-to($media) {
  $breakpoint: map-get($mediaBreakpoints, $media);

  @if ($breakpoint) {
    @media (max-width: ($breakpoint - 1)) {
      @content;
    }
  } @else {
    @media (max-width: ($media - 1)) {
      @content;
    }
  }
}

@mixin respond-above($media) {
  $breakpoint: map-get($mediaBreakpoints, $media);

  @if ($breakpoint) {
    @media (min-width: ($breakpoint)) {
      @content;
    }
  } @else {
    @media (min-width: ($media)) {
      @content;
    }
  }
}

@mixin app-container() {
  padding: 0 3rem;
}
