:root {
  --clr-black: #000000;
  --clr-chinese-black: #101010;
  --clr-black-icon: #202025;
  --clr-white: #ffffff;
  --clr-nickel: #66697d;
  --clr-gray: #dbdbdb;
  --clr-gray-lead: #4c5261;
  --clr-goldenrod-yellow: #dedb7b;
  --clr-orange-soda: #ff5d42;
}
