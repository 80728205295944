@import 'styles/_mixins.scss';

.legal-col-left, .legal-col-right {
  border: 1px solid var(--clr-black);
}

.legal-col-left {
  border-bottom: 0;
}

@include respond-above(x-tablet) {
  .legal-col-left {
    border-bottom: 1px solid var(--clr-black);
  }

  .legal-col-right {
    border-top: 1px solid var(--clr-black);
  }
}
