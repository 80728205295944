@import 'styles/_mixins.scss';

.default-title {
  font-size: 4.8rem;
  line-height: 5.8rem;
}

@include respond-to(x-medium) {
  .default-title {
    font-size: 4rem;
    line-height: 4rem;
  }
}

@include respond-to(medium) {
  .default-title {
    font-size: 3.2rem;
    line-height: 4rem;
  }
}

@include respond-to(x-tablet) {
  .default-title {
    font-size: 2.8rem;
    line-height: 4rem;
  }
}

@include respond-to(tablet) {
  .default-title {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@include respond-to(small) {
  .default-title {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}
