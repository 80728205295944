@import 'styles/_mixins.scss';

.intro {
  position: relative;
}

.intro__video {
  width: 100%;
  box-sizing: border-box;
  border: 0.4rem solid var(--clr-white);
  border-radius: 2.4rem;
}

#bg-video-mobile {
  display: none;
}

.intro__content {
  padding: 0.8rem;
  padding-top: 10rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  box-sizing: border-box;
}

.intro__header {
  @include app-container();
  margin: 0.8rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(#ffffff, 0.08);
  border-radius: 1.8rem;

  .intro__lang-toggle {
    background-color: transparent;
    border: none;
    font-size: 1.4rem;

    &:active,
    &:hover {
      background-color: transparent !important;
    }
  }

  .intro__lang-item {
    font-size: 1.4rem;
  }
}

.intro__title {
  @include app-container();
  max-width: 55rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: var(--clr-goldenrod-yellow);
}

.intro__qr {
  @include app-container();
}

.intro__qr-container {
  max-width: 27rem;
  margin-bottom: 4rem;
  padding: 2.4rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  background-color: var(--clr-black-icon);
  border-radius: 2.4rem;

  img {
    width: 5.6rem;
    height: 5.6rem;
  }

  .intro__qr-label {
    width: 20rem;
    color: var(--clr-white);
  }
}

.intro__links {
  @include app-container();
  margin-bottom: 2.4rem;
  display: none;
  font-size: 1.2rem;
  font-family: 'FK Grotesk Thin';

  .intro__links-item {
    margin-right: 2rem;
    display: flex;
    align-items: center;
    direction: ltr;
    color: var(--clr-white);
    text-decoration: none;

    &:hover {
      color: var(--clr-orange-soda);
    }

    .intro__links-container {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.8rem;
      background-color: var(--clr-black);
    }
  }
}

@include respond-to(x-medium) {
  .intro__qr-container {
    padding: 2rem;

    img {
      width: 5.2rem;
      height: 5.2rem;
    }
  }
}

@include respond-to(medium) {
  .intro__qr-container {
    padding: 1.6rem;

    img {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
}

@include respond-to(x-tablet) {
  .intro__qr-container {
    max-width: 20rem;
    padding: 1.2rem;

    img {
      width: 4.4rem;
      height: 4.4rem;
    }
  }

  .intro__qr-label {
    font-size: 1.2rem;
  }
}

@include respond-to(tablet) {
  .intro__qr-container {
    img {
      width: 4rem;
      height: 4rem;
    }
  }
}

@include respond-to(small) {
  #bg-video {
    display: none;
  }

  #bg-video-mobile {
    display: block;
  }

  .intro__title {
    max-width: 30rem;
  }

  .intro__qr {
    display: none;
  }

  .intro__links {
    display: flex;
  }
}